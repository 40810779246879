import React from "react"
import Layout from "@components/layout";
import FormsParts from "@components/forms-contactform7";

// 言語用Jsonファイル
import { LangSetting } from "@components/language-setting.js";
import langEn from "@content/locales/en/pamm.json";
import langJa from "@content/locales/ja/pamm.json";

import { SeoContents } from "@components/seo-data.js" // Seo情報設定

// Import SVG
import pammimg01 from '@images/pamm_new_img1.webp';

const Pamm = ( props ) => {
  
  // 言語設定
  const setLang = LangSetting( props, langEn, langJa ).SetLang;
  const postLang = LangSetting( props, langEn, langJa ).PostLang;

  // 構造化データ用配列
  // 作成・更新日
  const date = {modified: process.env.MODIFIED};

  // Seo情報設定
  const pathName = 'pamm'; // ファイル名
  const title = postLang["page-title"];
  const description = postLang["page-description"];
  const keywords = postLang["page-keywords"];
  const seoTag = SeoContents( pathName, setLang, title, description, keywords, date);
  
  
   // 言語別URL設定
  let langUrl;
  (setLang === 'en') ? langUrl = '/' : langUrl = '/' + setLang + '/';
  const partnerPath1 = langUrl + "ib/";
  const partnerPath2 = langUrl + "white-label/";
  
  return (
  <>
    {seoTag}
    <Layout>
      <div className="FV pt-5 pb-8 bg-fixed" langType={setLang}>
        <div className="max-w-xl mx-auto px-6 md:max-w-5xl md:pt-5 pt-2 md:flex">
          <div className=" text-left h1_filed">
            <h1 className="md:text-[60px] text-[35px] font-semibold">{postLang["pamm-h1"]}</h1>
            <div className="text-base max-w-[680px]">
              <p>{postLang["pamm-h1-txt"]}</p>
            </div>
          </div>
        </div>
      </div>




      <div className="max-w-xl mx-auto  pt-0 md:pt-5 pb-11 md:max-w-5xl px-6 mb-8 md:mb-10">

      <div className="mb-10 flex flex-wrap justify-between mt-10">
          <div class="text-left  md:mb-5 mb-10 md:max-w-[600px]">
            <h2 className="font-semibold tracking-wide text-[26px] md:text-[40px] mb-5">{postLang["pamm-h2"]}</h2>
            <p className="leading-7">{postLang["pamm-txt_7"]}{postLang["pamm-txt_8"]}</p>
          </div>
          <div className="w-[100%] md:max-w-[350px]  ">
             <img src={pammimg01} alt="" />
          </div>
        </div>


        <h2 className="font-semibold tracking-wide text-[26px] md:text-[40px] mb-5">{postLang["pamm-h2_3"]}</h2>
        <div className="text-[20px] mb-10 pamm_list1">
          <p><span>{postLang["pamm-txt_1"]}</span></p>
          <p><span>{postLang["pamm-txt_2"]}</span></p>
          <p><span>{postLang["pamm-txt_3"]}</span></p>
        </div>

        <h2 className="font-semibold tracking-wide text-[26px] md:text-[40px] mb-5">{postLang["pamm-h2_4"]}</h2>
        <div className="text-[20px]  pamm_list2">
          <p>{postLang["pamm-txt_4"]}</p>
          <p>{postLang["pamm-txt_5"]}</p>
          <p>{postLang["pamm-txt_6"]}</p>
        </div>
      </div>

      <div className="Feature pt-5  bg-fixed">
        <div className="max-w-xl mx-auto  md:max-w-5xl px-6 mb-10">
          <div className="text-left mt-7 mb-10">
            <h2 className="font-semibold tracking-wide text-[26px] md:text-[40px]">{postLang["pamm-h2_2"]}</h2>
          </div>
          <p className="text-left mb-10 md:mb-20 ">{postLang["pamm-h2-txt"]}<br></br>{postLang["pamm-h2-txt_2"]}</p>
          <div className="max-w-2xl mb-10"><FormsParts langType={setLang} /></div>
        </div>
        <div className="mt-11 py-14 py-14  md:py-10 md:py-10 ">
        <div className="flex-1 mx-auto max-w-3xl md:flex md:justify-around">


            <a href={partnerPath1} className="rounded block  md:max-w-xs mx-5 text-center text-white font-bold py-5 px-16 my-2 bg-black hover:scale-[1.05] duration-300 ">
            <p className="inline-block"><span>{postLang["ibp-h1"]}</span><br></br><span className="text-[10px] leading-4 inline-block">{postLang["ibp-h1-txt"]}</span></p>
            </a>


            <a href={partnerPath2} className="rounded block  mx-5 md:max-w-xs text-center text-white font-bold py-5 px-16 my-2 bg-black hover:scale-[1.05] duration-300  ">
              <p className="inline-block"><span>{postLang["wrp-h1"]}</span><br></br><span className="text-[10px]">{postLang["wrp-h1-txt"]}</span></p>
            </a>
          </div>
        </div>
      </div>
    </Layout>
  </>
  )
}
export default Pamm